// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "../stylesheets/application.scss";

document.addEventListener("DOMContentLoaded", function () {
  const navbarToggler = document.querySelector(".navbar__toggler");
  const navbarMenu = document.querySelector(".navbar__menu");
  navbarToggler.addEventListener("click", function (event) {
    event.preventDefault();
    navbarMenu.classList.toggle("visible");
  });

  const dropdown = document.querySelector(".dropdown");
  dropdown.addEventListener("click", function (event) {
    // event.preventDefault();
    event.stopPropagation();
    const dropdownMenu = document.querySelector(".dropdown__menu");
    const dropdownToggler = document.querySelector(".dropdown__toggler");
    dropdownMenu.classList.toggle("visible");
    dropdownToggler.classList.toggle("active");
  });
});

window.addEventListener("click", function (event) {
  // close all dropdowns
  if (!event.target.matches(".dropdown__toggler")) {
    const dropdowns = document.querySelectorAll(".dropdown__menu");
    for (let i = 0; i < dropdowns.length; i++) {
      const dropdown = dropdowns[i];
      if (dropdown.classList.contains("visible")) {
        dropdown.classList.remove("visible");
      }
    }
    const dropdownTogglers = document.querySelectorAll(".dropdown__toggler");
    for (let i = 0; i < dropdownTogglers.length; i++) {
      const dropdownToggler = dropdownTogglers[i];
      if (dropdownToggler.classList.contains("active")) {
        dropdownToggler.classList.remove("active");
      }
    }
  }
});
